import { useEffect, useRef, useState } from "react";
import { config } from "./config.js";
import videoBgDesktop from './bg.jpg';
// import videoBgMobile from './video-mob.jpg';

const videoDesktop = 'https://app-argo-prod.s3.us-west-1.amazonaws.com/static/outlaw-desk.mp4'
// const videoMobile = 'https://app-argo-prod.s3.us-west-1.amazonaws.com/static/humanx-promo-mob.mp4'

const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/g;

let Airtable = require('airtable');
let base = new Airtable({apiKey: config.airTable}).base(config.base);

function App() {
    const [windowWidth, windowHeight] = useRef([window.innerWidth, window.innerHeight]).current
    const videoRef = useRef(null)
    const [email, setEmail] = useState('')
    const [success, setSuccess] = useState(false)
    // const bgVideo = windowWidth < 768 ? videoMobile : videoDesktop
    // const bgPoster = windowWidth < 768 ? videoBgMobile : videoBgDesktop
    const bgVideo = videoDesktop
    const bgPoster = videoBgDesktop
    if (windowWidth < 768) {
        document.documentElement.style.setProperty('--app-height', `${windowHeight}px`)
    }

    useEffect(() => {
        if (videoRef) {
            videoRef.current.play()
        }
    }, [videoRef])

    const onEmailChange = (e) => {
        const value = e.target.value
        if (value) {
            setEmail(value)
        } else {
            setEmail('')
            setSuccess(false)
        }
    }

    const onEmailFilled = (e) => {
        e.preventDefault()
        const formSuccess = !!(email && email.length && email.match(isValidEmail))
        setSuccess(formSuccess)
        if (formSuccess) {
            base(config.table).create([{ "fields": { email } }]);
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        onEmailFilled(e)
    }

    return (
        <div className="main">
            <div className="videoBG">
                <video ref={videoRef}
                       className="videoElement"
                       poster={bgPoster}
                       preload="auto"
                       muted="muted"
                       loop="loop"
                       playsInline="playsinline"
                >
                    <source src={bgVideo} type="video/mp4"/>
                </video>
            </div>
            <div className="content">
                <div className="logo"></div>
                <div className="description">
                    New Generation Infrastructure for a Better <br/>
                    and Safer Social Network and Entertainment Experience
                </div>
                <form className={ success ? 'form success' : "form"} onSubmit={onSubmit}>
                    <input className="email"
                           onChange={onEmailChange}
                           name="email"
                           type="email"
                           placeholder="Please enter your email address"
                           value={email}
                    />
                    <button onClick={onEmailFilled}
                            disabled={!email}
                            type="button"
                            className="btn"
                    >{ success ? 'Thanks!' : 'Notify me' }
                    </button>
                </form>
                { success ?
                    <div className="success-block">
                        <span className="icon-success"></span>
                        We will notify you of interesting news.
                    </div>
                : '' }
            </div>
            <footer>
                <div className="copy">Outlaw Tech © { new Date().getFullYear() }</div>
                <a href="mailto:info@ot4.life"
                   className="contact"
                   target="_blank"
                   rel="noreferrer"
                >info@ot4.life</a>
            </footer>
        </div>
    );
}

export default App
